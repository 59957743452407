import React from 'react';
import {
  PageHero,
  GoodBad,
  GoodBadItem,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Date & Time" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Content Elements" tierThree="Date & Time" />
      <Section>
        <Paragraph>
          One thing to keep in mind with everything below:{' '}
          <Link href="https://momentjs.com/docs/#/displaying/format/">moment.js</Link> isn’t required, but definitely
          preferred for the sake of uniformity—at least for now.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Date</SectionSubhead>
        <Paragraph>
          More often than not, you’ll only need month, day and year. The day should <em>always</em> be an Arabic figure
          (Aug 10), never ordinal (Aug 10th).
        </Paragraph>
        <Paragraph>
          Months should be abbreviated to the first three letters (handled by moment.js), but the year will always be
          the <strong>full four digits</strong>.
        </Paragraph>
        <Paragraph>
          We realize the order of these elements changes depending on locale. Rather than list all possible
          arrangements, we’re trusting moment.js to call those shots, as well.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Sept. 28th, 2019</Paragraph>
            <Paragraph>31 October ’19</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Sep 28, 2019</Paragraph>
            <Paragraph>31 Oct 2019</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Always use Arabic figures, never ordinal. (No 1st, 2nd, etc.)</li>
          <li>Abbreviated months should be three letters, no period.</li>
          <li>Never shorten the year. It could get confused with the actual day.</li>
        </List>
      </Section>

      <Section>
        <SectionSubhead>Numbers Only</SectionSubhead>
        <Paragraph>
          <strong>Avoid the numbers-only format whenever possible.</strong> For one thing, it’s not as clear, especially
          when numbers could represent day or month. For another, there’s no good answer re: whether or not we put 0
          before a single-digit month or day. Remember, we’re going for consistency.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>9/10/2019</Paragraph>
            <Paragraph>09/10/2019</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Sep 10, 2019</Paragraph>
            <Paragraph>9 Oct 2019</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Don't rely on numbers to represent a month.</li>
          <li>Keep in mind how the order may change after localization.</li>
        </List>
        <Paragraph />
        <Paragraph>
          If you have a case where all-numbers is the better option,{' '}
          <Link href="http://bit.ly/uniformtriage">talk to Uniform</Link>.
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Days of the Week</SectionSubhead>
        <Paragraph>
          In the case of full schedules and any <em>upcoming</em> event, include day of the week.
        </Paragraph>
        <Paragraph>
          When possible, spell out the day. If it must be abbreviated, trim to three letters with no period (same as all
          other abbreviations).
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Jun 17, 2019</Paragraph>
            <Paragraph>Jun 20, 2019</Paragraph>
            <Paragraph>Jun 23, 2019</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Monday, Jun 17, 2019</Paragraph>
            <Paragraph>Thursday, Jun 20, 2019</Paragraph>
            <Paragraph>Sunday, Jun 23, 2019</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Differentiate otherwise identical items with days of the week.</li>
          <li>Spell out the day whenever possible.</li>
        </List>
      </Section>

      <Section>
        <SectionSubhead>Time</SectionSubhead>
        <Paragraph>
          Whenever you include time, it should come with am or pm. Refer to all times with actual numbers—no exceptions
          for <em>noon</em> or <em>midnight</em>.
        </Paragraph>
        <Paragraph>
          Avoid the 24-hour format (it’s why the am/pm is so important). Because durations occasionally appear in the
          product, we want to avoid any possible confusion of the actual time being a video length.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Oct 25, 10:30 A.M.</Paragraph>
            <Paragraph>Wednesday, 25 Oct at noon</Paragraph>
            <Paragraph>22 Feb, 15:05</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Oct 25, 10:30am</Paragraph>
            <Paragraph>Wednesday, 25 Oct, 12:00pm</Paragraph>
            <Paragraph>22 Feb, 3:05pm</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Always include am and pm, lowercase with no periods.</li>
          <li>Don’t add a space before either.</li>
        </List>
      </Section>

      <Section>
        <SectionSubhead>Time Zone</SectionSubhead>
        <Paragraph>
          Only include time zone when the times pertain to a specific place, like Hudl HQ. All other times displayed in
          schedules or feeds can be implied as local.
        </Paragraph>
        <Paragraph>
          To avoid any Daylight Saving confusion, don’t include S(tandard) or D(aylight) in the time zone abbreviation.
          That means Hudl HQ will always be CT, no matter the time of year.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Next game: Saturday, Aug 15, 7:00pm PDT</Paragraph>
            <Paragraph>Contact Hudl Support 8:00am to 7:00pm, M–F.</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Next game: Saturday, Aug 15, 7:00pm</Paragraph>
            <Paragraph>Contact Hudl Support 8:00am to 7:00pm CT, M–F.</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Only use time zones when the location may not be local.</li>
          <li>Ignore any mention of DST to avoid outdated or incorrect hours.</li>
        </List>
        <Paragraph />
        <Paragraph>
          You can find other office time zones{' '}
          <Link href="https://sync.hudlnet.com/display/FAC/Office+Addresses">here</Link>.
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Life Span</SectionSubhead>
        <Paragraph>
          For longer life span items like feed cards and exchanges, it’s important to clearly communicate exactly when a
          thing was created or shared.
        </Paragraph>
        <Paragraph>Note that the concept of “life span” does not apply to schedules and planned events.</Paragraph>
        <Paragraph>
          Avoid putting about before these timestamps. The user shouldn’t second-guess how old an item really is.{' '}
        </Paragraph>
        <List type="unordered">
          <li>
            If it’s new within the hour, count the minute, e.g., <strong>13 minutes ago</strong>. (This is the only time
            we’ll use <em>ago</em>.)
          </li>
          <li>
            For anything shared or created that day, use only the time—<strong>2:16pm</strong>.
          </li>
          <li>
            If it was yesterday, say so! <strong>Yesterday at 2:16pm</strong>.
          </li>
          <li>
            For anything in the last week, mention the day, not the date—<strong>Wednesday at 2:16pm</strong>.
          </li>
          <li>
            Once that week is up, use the date instead, e.g., <strong>Mar 6 at 2:16pm</strong>.
          </li>
          <li>
            After a month, drop the time to simply say <strong>Mar 6</strong>.
          </li>
          <li>
            After a year, add the year—<strong>Mar 6, 2019</strong>.
          </li>
        </List>
        <Paragraph />
        <Paragraph>
          When we generalize with “one week ago” or “three months ago”, timelines become ambiguous and the user could
          lose track of what’s relevant when. Providing the right amount of detail makes it easier for them to reference
          past interactions and plan new ones accordingly.
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Durations</SectionSubhead>
        <Paragraph>
          Durations should always include digits for minutes and seconds—even for those under a minute long. This
          applies not only to the length of a video, but also to how long a user watches video (found in team
          management).
        </Paragraph>{' '}
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>59s</Paragraph>
            <Paragraph>00:08:14</Paragraph>
            <Paragraph>01:23:15:41</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>0:59</Paragraph>
            <Paragraph>8:14</Paragraph>
            <Paragraph>1:23:15.41</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Anything 59 seconds or less should still include a zero in the minute slot.</li>
          <li>Durations under 10 minutes can be displayed as a single digit, no need to put a zero on the front.</li>
          <li>
            Hour digits are not required until the video (or user activity) hits the hour mark. If it’s likely listed
            items will reach an hour in length, a zero may be added for consistent space—just make sure the view isn’t
            full of empty hours.
          </li>
          <li>
            Milliseconds are also not required and shouldn’t be used in a static display of time. If you want to display
            time elapsed as a video plays, be sure to set off the milliseconds with a period rather than a colon.
          </li>
          <li>
            <strong>
              Never use <em>h</em>, <em>m</em>, or <em>s</em> for hours, minutes and seconds. Stick to the colon format
              for everything.
            </strong>
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
